import { EstablishmentLocation } from 'src/app/data_structure/EstablishmentLocation';
import { WholeDish } from './../../../data_structure/WholeDish';
import { WholeMenu } from 'src/app/data_structure/WholeMenu';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { OCRService } from 'src/app/services/ocr.service';

@Component({
  selector: 'app-establishment-detail',
  templateUrl: './establishment-detail.component.html',
  styleUrls: ['./establishment-detail.component.scss']
})
export class EstablishmentDetailComponent {

  public menu = {} as WholeMenu;
  public firstCourseList?: WholeDish[];
  public secondCourseList?: WholeDish[];
  public dessertList?: WholeDish[];
  public state = "";
  public isLoading = false;
  public isError = false;
  public isAllergenInfoExpanded = false;

  constructor(
    public dialogRef: MatDialogRef<EstablishmentDetailComponent>,
    private ocrService: OCRService,
    @Inject(MAT_DIALOG_DATA) public restaurant: EstablishmentLocation
    ){}

  ngOnInit(){
    this.getMenu(this.restaurant.id);
  }

  getMenu(restaurantId:number) {
    this.isLoading = true;
    this.isError = false;
    this.ocrService.getMenu(restaurantId).subscribe((establishmentMenu: WholeMenu) => {
      if (establishmentMenu.dishes && establishmentMenu.dishes.length > 0){
        this.menu.dishes =  establishmentMenu.dishes;
        this.firstCourseList = establishmentMenu.dishes.filter(e => e.rel.dishType === "First");
        this.secondCourseList = establishmentMenu.dishes.filter(e => e.rel.dishType === "Second");
        this.dessertList = establishmentMenu.dishes.filter(e => e.rel.dishType === "Dessert");
        this.menu.price = establishmentMenu.price;
        console.log(establishmentMenu);
        this.isLoading = false;
      } else {
        this.isError = true;
        this.isLoading = false;
      }
    },
    (error) => {
      this.isError = true;
      this.isLoading = false;
    });
  }

}
