import { Component, ElementRef } from '@angular/core';
import { OCRService } from 'src/app/services/ocr.service';
import { OCRCheck } from 'src/app/data_structure/OCRCheck';
import { OCRResponse } from 'src/app/data_structure/OCRResponse';
import { ViewChild } from '@angular/core';
import { UserSingleton } from 'src/app/services/user-singleton';

@Component({
  selector: 'app-scaner',
  templateUrl: './scaner.component.html',
  styleUrls: ['./scaner.component.scss']
})
export class ScanerComponent {
  @ViewChild('imageInput')
  imageInputChild!: ElementRef;
  private ocrService;
  private fileName!: Blob;
  public imageUrl = "";

  public result = "";
  public code?: number;
  public CheckNumber?: number;
  public Year?: number;
  public Quantity?: number;
  public ExpirationDate?: string;

  public state = "";
  public showValidate = true;
  public showNewCheck = false;
  public showCancel = false;
  public hasResult = false;
  public isLoading = false;
  private userSingleton: UserSingleton;

  constructor(private _ocrService: OCRService, _userSingleton: UserSingleton) {
    this.ocrService = _ocrService;
    this.userSingleton = _userSingleton;
  }

  onFileChange(evt: any): void {
    this.fileName = evt.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.fileName);
    this.showCancel = true;
    reader.onload = () => {
      this.imageUrl = reader.result as string;
    }
  }

  public resetImage() {
    this.imageUrl = "";
    this.fileName = new Blob();
    this.imageInputChild.nativeElement.value = "";
    this.showCancel = false;
    this.showNewCheck = false;
    this.showValidate = true;
    this.hasResult = false;
    this.isLoading = false;
  }

  public scanCheck() {
    this.isLoading = true;
    const reader = new FileReader();
    this.state = "Procesando";
    reader.readAsDataURL(this.fileName);
    reader.onload = () => {
        var file = reader.result?.toString().split(",")[1];
        this.state = "Cargando";
        this.showCancel = false;
        this.showNewCheck = false;
        this.showValidate = false;
        this.ocrService.postCheck(file, this.userSingleton.getEstablishment().id).subscribe((response: OCRCheck) => { 
          this.state = "";
          this.isLoading = false;
            this.hasResult = true;
            this.showNewCheck = true;
            this.result = response.responseCheckvs.errors[0].responseMessage;
            this.code = response.responseCheckvs.errors[0].errorCode;
            this.CheckNumber = response.checkNumber;
            this.Year = response.year;
            this.Quantity = response.quantity;
            this.ExpirationDate = response.expirationDate;
         }, (error) => {
          this.state = error.message;
          this.resetImage();
         },
         () => {

         });
    };
    
  }
}
