<div id="dialog-content">
    <mat-form-field class="example-full-width">
        <mat-label>Nombre del Plato</mat-label>
        <input matInput [(ngModel)]="data.rel.displayName" />
    </mat-form-field>
    <div style="display: flex;">
        <h3>Tipo de Plato: </h3>
        <button mat-fab class="first" *ngIf="data.rel.dishType == 'First'" (click)="data.rel.dishType = 'Second'"
            aria-label="Primer Plato">
            <h3 class="text-icon">1</h3>
        </button>
        <button mat-fab class="second" *ngIf="data.rel.dishType == 'Second'" (click)="data.rel.dishType = 'Dessert'"
            aria-label="Segundo Plato">
            <h3 class="text-icon">2</h3>
        </button>
        <button mat-fab class="dessert" *ngIf="data.rel.dishType == 'Dessert'" (click)="data.rel.dishType = 'First'"
            aria-label="Postre">
            <mat-icon class="dessert-icon">local_cafe</mat-icon>
        </button>
    </div>
    <mat-grid-list cols="3" rowHeight="1:1">
        <mat-grid-tile>
            <div>
                <h4 class="text-allergen">Cereal</h4>
                <mat-slide-toggle class="setting" [(ngModel)]="allergens.cereal"></mat-slide-toggle>
            </div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div>
                <h4 class="text-allergen">Apio</h4>
                <mat-slide-toggle class="setting" [(ngModel)]="allergens.celery"></mat-slide-toggle>
            </div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div>
                <h4 class="text-allergen">Crustaceo</h4>
                <mat-slide-toggle class="setting" [(ngModel)]="allergens.crustacean"></mat-slide-toggle>
            </div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div>
                <h4 class="text-allergen">Huevo</h4>
                <mat-slide-toggle class="setting" [(ngModel)]="allergens.egg"></mat-slide-toggle>
            </div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div>
                <h4 class="text-allergen">Pescado</h4>
                <mat-slide-toggle class="setting" [(ngModel)]="allergens.fish"></mat-slide-toggle>
            </div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div>
                <h4 class="text-allergen">Lupino</h4>
                <mat-slide-toggle class="setting" [(ngModel)]="allergens.lupin"></mat-slide-toggle>
            </div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div>
                <h4 class="text-allergen">Lacteos</h4>
                <mat-slide-toggle class="setting" [(ngModel)]="allergens.milk"></mat-slide-toggle>
            </div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div>
                <h4 class="text-allergen">Molusco</h4>
                <mat-slide-toggle class="setting" [(ngModel)]="allergens.mollusc"></mat-slide-toggle>
            </div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div>
                <h4 class="text-allergen">Mostaza</h4>
                <mat-slide-toggle class="setting" [(ngModel)]="allergens.mustard"></mat-slide-toggle>
            </div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div>
                <h4 class="text-allergen">Nueces</h4>
                <mat-slide-toggle class="setting" [(ngModel)]="allergens.nut"></mat-slide-toggle>
            </div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div>
                <h4 class="text-allergen">Cacahuete</h4>
                <mat-slide-toggle class="setting" [(ngModel)]="allergens.peanut"></mat-slide-toggle>
            </div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div>
                <h4 class="text-allergen">Sesamo</h4>
                <mat-slide-toggle class="setting" [(ngModel)]="allergens.sesameSeed"></mat-slide-toggle>
            </div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div>
                <h4 class="text-allergen">Soja</h4>
                <mat-slide-toggle class="setting" [(ngModel)]="allergens.soybean"></mat-slide-toggle>
            </div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div>
                <h4 class="text-allergen">Dioxido de
                    Sulfuro</h4>
                <mat-slide-toggle class="setting" [(ngModel)]="allergens.sulphurDioxide"></mat-slide-toggle>
            </div>
        </mat-grid-tile>
    </mat-grid-list>
    <button mat-raised-button color="accent" class="sendButton" (click)="onNoClick()">Salir y Guardar</button>
    <button mat-raised-button color="warn" class="sendButton" (click)="unregister(data)">Eliminar Plato</button>
</div>