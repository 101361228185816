
<mat-card id="component">
    <div class="loadingDiv">
        <h3 *ngIf="textLoading">{{this.state}}</h3>
        <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
    </div>
    <div *ngIf="!textLoading" id="consignment-info">
        <h3>Importe total: {{total}}</h3>
    </div>
    <mat-grid-list cols="3" rowHeight="1:1" id="check-list">
        <mat-grid-tile (click)="detailCheck(check)" class="cell" *ngFor="let check of presentedChecks">
            <img *ngIf="showPhoto == 'true'" [src]="check.urlImg" class="image-preview">
            <h3 *ngIf="showPhoto == 'false'">{{check.checknum}}</h3>
        </mat-grid-tile>
    </mat-grid-list>
    <button mat-raised-button *ngIf="!textLoading" color="accent" id="send-consignment" (click)="sendConsignment()">Enviar Remesa</button>
</mat-card>