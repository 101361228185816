import { OCRService } from 'src/app/services/ocr.service';
import { EstablishmentDetailComponent } from './establishment-detail/establishment-detail.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, ElementRef } from '@angular/core';
import { OSM } from 'ol/source';
import { EstablishmentLocation } from 'src/app/data_structure/EstablishmentLocation';
import { Map } from 'ol';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';
import { fromLonLat } from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import { SearchArea } from 'src/app/data_structure/SearchArea';
import { UserSingleton } from 'src/app/services/user-singleton';
import { ResponseEstablishmentLocation } from 'src/app/data_structure/ResponseEstablishmentLocation';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent {

  public restaurantList: EstablishmentLocation[] = [];
  public restaurantHighlightedList: EstablishmentLocation[] = [];
  public searchArea: SearchArea = {
    address: 'Puerta del sol, madrid',
    radius: 200,
    dish: "",
    menu: false
  }
  public isLoading = false;
  public state = "";
  public isSearchDish = false;

  public markerList: Feature[] = [];
  public markerHighlightedList: Feature[] = [];
  private clickedRestaurant = {} as EstablishmentLocation;

  //mapFeatures
  private map!: Map;
  private iconFadedStyle: Style = new Style({
    image: new Icon({
      anchor: [0.5, 1],
      // anchorXUnits: 'fraction',
      // anchorYUnits: 'pixels',
      src: 'assets/marker_grey.png'
    })
  });
  private iconStyle: Style = new Style({
    image: new Icon({
      anchor: [0.5, 1],
      // anchorXUnits: 'fraction',
      // anchorYUnits: 'pixels',
      src: 'assets/marker.png'
    })
  });
  private markerHiliteLayer = new VectorLayer({
    source: new VectorSource(),
    style: this.iconStyle,
  });
  private markerLayer = new VectorLayer({
    source: new VectorSource(),
    style: this.iconFadedStyle,
  });

  userSingleton: UserSingleton;

  constructor (public dialog: MatDialog, private ocrService: OCRService, private elementRef: ElementRef,  userSingleton: UserSingleton){
    this.userSingleton = userSingleton;
  }

  ngOnInit(): void {
    if(this.userSingleton.getLastStreet() != null){
      this.searchArea.address = this.userSingleton.getLastStreet()!;
    }
    let coors = [-3.7033, 40.4167];
    if(this.userSingleton.getLastCoors() != null){
      coors = this.userSingleton.getLastCoors()!;
    }
    //get restaurants


    //set map
    this.map = new Map({
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      target: 'map',
      view: new View({ 
        center: fromLonLat(coors),
        zoom: 11,
        maxZoom: 18, 
      }),
    });
    this.getEstablishments();
    //set markers
    this.map.addLayer(this.markerLayer);
    this.map.addLayer(this.markerHiliteLayer);

  }

  getEstablishments(){
    this.ocrService.getMap(this.searchArea.address, this.searchArea.radius, this.searchArea.menu, this.searchArea.dish).subscribe((establishmentList: ResponseEstablishmentLocation) => {
      this.userSingleton.saveLastStreet(this.searchArea.address);
      this.userSingleton.saveCoors([establishmentList.longitud, establishmentList.latitud]);
      this.map.getView().setCenter(fromLonLat([establishmentList.longitud, establishmentList.latitud]));
      this.restaurantList = establishmentList.results.filter(e => e.highlight === false);
      this.restaurantHighlightedList = establishmentList.results.filter(e => e.highlight === true);
      //if (this.restaurantList.length <= 0) { //TODO: update dependnig on marker color
        //this.restaurantList = [...this.restaurantHighlightedList ];
        //this.restaurantHighlightedList = [];
      //}
      this.markerList = [];
      this.markerHighlightedList = [];
      this.markerLayer.getSource()?.clear();
      this.markerHiliteLayer.getSource()?.clear();
      this.restaurantList.forEach((restaurant:EstablishmentLocation) => {
        let marker = new Feature(new Point(fromLonLat([restaurant.longitud, restaurant.latitud])));
        this.markerList.push(marker);
        this.markerLayer.getSource()?.addFeature(marker);
        if (restaurant.nombre.includes(',')) {
          restaurant.nombre = this.reformEstablishmentName(restaurant.nombre);
        }
      });
      this.restaurantHighlightedList.forEach((restaurant:EstablishmentLocation) => {
        let marker = new Feature(new Point(fromLonLat([restaurant.longitud, restaurant.latitud])));
        this.markerHighlightedList.push(marker);
        this.markerHiliteLayer.getSource()?.addFeature(marker);
        if (restaurant.nombre.includes(',')) {
          restaurant.nombre = this.reformEstablishmentName(restaurant.nombre);
        }
      });
    });
  }

  reformEstablishmentName(name:string) {
    let rearrangeName = name.split(','); //split name "Nombre, El Restaurante" by comma 
          let mainName = rearrangeName[0];
          let rearrangeArticle = rearrangeName[1].split(' '); //get article from name "El Restaurante" and put it in the first position
          let restOfName: string = "";
          let articleName: string = "";
          if (rearrangeArticle[0] === ""){ //check if there is a space before the article in order to split the article from the rest of the name
            articleName = rearrangeArticle[1];
            restOfName = rearrangeArticle.slice(2, rearrangeArticle.length).join(' ');
          } else {
            articleName = rearrangeArticle[0];
            restOfName = rearrangeArticle.slice(1, rearrangeArticle.length).join(' ');
          }
          return articleName.concat(' ', mainName, ' ', restOfName);
  }

  onClickMap(evt: UIEvent) {
    //get map feature (marker)
    let pixel = this.map.getEventPixel(evt); 
    const feature = this.map.forEachFeatureAtPixel(pixel, function (feature) {
      return feature;
    });
    if (!feature) {
      return;
    }
    //find which marker has been clicked
    let clickedMarker = this.markerList.find(marker => 
      feature === marker
    ); 
    if (clickedMarker != undefined){
      let clickedIndex = this.markerList.indexOf(clickedMarker);
      this.clickedRestaurant = this.restaurantList[clickedIndex];
    }
    else{
      clickedMarker = this.markerHighlightedList.find(marker => 
        feature === marker
      ); 
      if (clickedMarker != undefined){
        let clickedIndex = this.markerHighlightedList.indexOf(clickedMarker);
        this.clickedRestaurant = this.restaurantHighlightedList[clickedIndex];
      }
    }

    //show info from clicked marker
    const dialogRef = this.dialog.open(EstablishmentDetailComponent, {
      width: '100%',
      height: '65%',
      maxWidth: '97%',
      position: {left: '2.5%', right: '2.5%'},
      data: this.clickedRestaurant
    });
  }

  switchSearchDish() {
    this.isSearchDish =! this.isSearchDish;
    //this.searchArea.dish = ""; 
  }

}
