import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { OCRService } from '../services/ocr.service';
import { OCRResponse } from '../data_structure/OCRResponse';
import { ScanerComponent } from './scaner/scaner.component';
import { UserSingleton } from '../services/user-singleton';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent {
  isExpanded = false;
  public moreRestaurants = false;
  public current = "menu";
  public currentRestaurant = "";
  public textRestaurant = "";
  public notificationConsignment = false;

  public userSingleton: UserSingleton;

  constructor( private router: Router, private _userSingleton: UserSingleton, private jwtHelper: JwtHelperService) {
    this.userSingleton = _userSingleton;

    this.currentRestaurant = this.userSingleton.getEstablishment().id.toString();
    
    if(this.userSingleton.getUser().listEstablishment.length > 1) {
      this.moreRestaurants = true;
      this.current = "restaurant";
    }
  }

  isUserAuthenticated() {
    const token = localStorage.getItem("jwt");
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      return true;
    }
    else {
      return false;
    }
  }

  public closeMenu(){
    this.isExpanded = false;
  }

  public showMenu(){
    this.isExpanded = !this.isExpanded;
    this.textRestaurant = this.userSingleton.getEstablishment().name;
    this.notificationConsignment = this.userSingleton.getEstablishment().hasCheckPresented;
  }

  public changeCategory(target: string){
    this.current = target;
    this.isExpanded = false;
  }

  public establishmentChanged(event: any){
    this.current = "dish";
  }

  public logOff(){
    //this.userSingleton.saveEstablishment("");
    //this.userSingleton.user = undefined;
    localStorage.removeItem("jwt");
    this.router.navigate(['login']);
  }

}
