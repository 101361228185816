<mat-card id="component">
    <div id="loginDiv">
        <img src="assets/upLogo.jfif" id="image-logo">
        <mat-form-field class="field-login">
            <mat-label>Usuario</mat-label>
            <input matInput placeholder="" [(ngModel)]="username" (ngModelChange)="checkLogin()">
        </mat-form-field>
        <mat-form-field class="field-login">
            <mat-label>Contraseña</mat-label>
            <input type="password" matInput placeholder="" [(ngModel)]="password" (ngModelChange)="checkLogin()">
        </mat-form-field>
        <div>
            <!-- <button class="b-btn" id="logInButton" (click)="logIn()" [disabled]="disableLogin || isLoading">Entrar</button>
            <button class="b-btn" id="logInGuestButton" (click)="logInGuest()">Entrar como invitado</button> -->
            <button mat-raised-button color="accent" id="logInButton" (click)="logIn()" [disabled]="disableLogin || isLoading">Entrar</button>
            <button mat-raised-button color="accent" id="logInGuestButton" (click)="logInGuest()">Entrar como invitado</button>
        </div>
        <div class="loadingDiv">
            <h3 *ngIf="msgLogin">{{this.state}}</h3>
            <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
        </div>
    </div>



</mat-card>