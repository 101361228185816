import { Injectable } from "@angular/core";
import { RelEstablishment } from "../data_structure/RelEstablishment";
import { ResultLogin } from "../data_structure/ResultLogin";

@Injectable({
    providedIn: 'root'
  })
  export class UserSingleton {

    public saveUser(user: ResultLogin) {
      localStorage.setItem("user", JSON.stringify(user));
    }

    public getUser(): ResultLogin{
      return JSON.parse(localStorage.getItem("user")!);
    }

    public saveLastStreet(street: string){
      localStorage.setItem("street", street);
    }

    public getLastStreet(): string | null {
      return localStorage.getItem("street");
    }

    public saveEstablishment(currentRestaurant: RelEstablishment){
      localStorage.setItem("rel", JSON.stringify(currentRestaurant));
    }

    public getLastCoors(): number[] | null {
      return JSON.parse(localStorage.getItem("coors")!);
    }

    public saveCoors(coors: number[]){
      localStorage.setItem("coors", JSON.stringify(coors));
    }

    public getEstablishment(): RelEstablishment {
      return JSON.parse(localStorage.getItem("rel")!);
    }

    public savePhotoConfig(condition: string) {
      localStorage.setItem("savePhoto", condition);
    }

    public getPhotoConfig(): string | null{
      return localStorage.getItem('savePhoto');
    }

  }