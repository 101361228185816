import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable } from '@angular/material/table';
import { DataNewDish } from 'src/app/data_structure/DataNewDish';
import { DataNewMenu } from 'src/app/data_structure/DataNewMenu';
import { RelDishEstablishment } from 'src/app/data_structure/RelDishEstablishment';
import { WholeDish } from 'src/app/data_structure/WholeDish';
import { WholeMenu } from 'src/app/data_structure/WholeMenu';
import { OCRService } from 'src/app/services/ocr.service';
import { UserSingleton } from 'src/app/services/user-singleton';
import { DishDetailComponent } from './dish-detail/dish-detail.component';

@Component({
  selector: 'app-dish-day',
  templateUrl: './dish-day.component.html',
  styleUrls: ['./dish-day.component.scss']
})
export class DishDayComponent {

  @ViewChild(MatTable) table!: MatTable<WholeDish>;
  @ViewChild('imageInput')
  imageInputChild!: ElementRef;
  
  public OptionSelected = false;
  public mode = 'loading';
  private fileName!: Blob;
  public imageUrl = "";
  private ocrService;
  public showCancel = false;
  private userSingleton: UserSingleton;
  public price = 0;

  public state = "";
  public showValidate = true;
  public hasResult = false;
  public isLoading = false;
  public disabledMenu = true;

  public dataSource!: WholeDish[];
  public displayedColumns: string[] = ['displayName', 'alergenos', 'tipo'];

  constructor(private _ocrService: OCRService, _userSingleton: UserSingleton, public dialog: MatDialog, public snackBar: MatSnackBar) {
    this.ocrService = _ocrService;
    this.userSingleton = _userSingleton;
    this.dataSource = [];
    this.checkDishDay();
  }

  public changeMode(mode: string){
    this.mode = mode;
  }

  onFileChange(evt: any): void {
    this.fileName = evt.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.fileName);
    this.showCancel = true;
    reader.onload = () => {
      this.imageUrl = reader.result as string;
    }
  }

  public resetImage() {
    this.imageUrl = "";
    this.fileName = new Blob();
    this.imageInputChild.nativeElement.value = "";
    this.showCancel = false;
    this.showValidate = true;
    this.hasResult = false;
    this.isLoading = false;
  }

  public checkDishDay(){
    this.state = "Cargando";
    this.ocrService.getMenu(this.userSingleton.getEstablishment().id).subscribe((response: WholeMenu) => {
      if(response.dishes != null && response.dishes.length > 0)
      {
        this.disabledMenu = false;
        this.mode = 'manual';
        this.dataSource = response.dishes;
        this.price = response.price;
      }
      else{
        this.mode = 'none';
      }
    },
    (error) => {
      this.state = error.message;
    });
  }

  public persistMenu(){
    var newData: DataNewDish[];
    newData = [];
    
    this.dataSource.forEach(element => {
      var newElement = <DataNewDish>{};
      if(element.allergen != null) {
        newElement.celery = element.allergen.celery;
        newElement.cereal = element.allergen.cereal;
        newElement.crustacean = element.allergen.crustacean;
        newElement.egg = element.allergen.egg;
        newElement.fish = element.allergen.fish;
        newElement.lupin = element.allergen.lupin;
        newElement.milk = element.allergen.milk;
        newElement.mollusc = element.allergen.mollusc;
        newElement.mustard = element.allergen.mustard;
        newElement.nut = element.allergen.nut;
        newElement.peanut = element.allergen.peanut;
        newElement.sesameSeed = element.allergen.sesameSeed;
        newElement.soybean = element.allergen.soybean;
        newElement.sulphurDioxide = element.allergen.sulphurDioxide;
      }
      newElement.displayName = element.rel.displayName;
      newElement.dishType = element.rel.dishType;
      newData.push(newElement);
    });
    this.mode = 'loading';
    var menu = <DataNewMenu>{};
    menu.dishes = newData;
    menu.price = 0;
    menu.price = +this.price.toString().replace(",",".");
    this.ocrService.persistMenu(menu, this.userSingleton.getEstablishment().id).subscribe((response: boolean) => { 
      this.mode = 'manual'
      
      var establishment = this.userSingleton.getEstablishment();
      establishment.hasMenuToday = true;
      this.userSingleton.saveEstablishment(establishment);
      var user = this.userSingleton.getUser();
      user.listEstablishment.find(x => x.id == this.userSingleton.getEstablishment().id)!.hasMenuToday = true;
      this.userSingleton.saveUser(user);

      this.snackBar.open("Menú cargado", "Cerrar", {
        duration: 6000,
      });
    }, (error: any) => {
      this.snackBar.open(error.message, "Cerrar", {
        duration: 10000,
      });
    })
  }

  public addDish(){
    var newDish = <WholeDish>{};
    var nameDish= <RelDishEstablishment>{};
    nameDish.displayName = "Plato Nuevo";
    nameDish.dishType = "First";
    newDish.rel = nameDish;
    this.disabledMenu = false;
    this.dataSource.push(newDish);
    this.table.renderRows();
  }

  public editDish(wholeDish: WholeDish){
      const dialogRef = this.dialog.open(DishDetailComponent, {
        width: '100%',
        height: '65%',
        maxWidth: '97%',
        position: {left: '2.5%', right: '2.5%'},
        data: wholeDish
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != null){
          this.dataSource.splice(this.dataSource.indexOf(result), 1);
          if(this.dataSource.length == 0)
            this.disabledMenu = true;
        }
        this.table.renderRows();
      });
  }

  public scanMenu(){
    this.isLoading = true;
    const reader = new FileReader();
    this.state = "Procesando";
    reader.readAsDataURL(this.fileName);
    reader.onload = () => {
        var file = reader.result?.toString().split(",")[1];
        this.state = "Cargando";
        this.showCancel = false;
        this.showValidate = false;
        this.ocrService.postMenu(file, this.userSingleton.getEstablishment().id).subscribe((response: WholeMenu) => { 
          this.state = "";
          this.isLoading = false;
          this.hasResult = true;
          this.mode = "manual";
          response.dishes.forEach(element => {
            this.disabledMenu = false;
            if(element.rel.dishType == null) {
              element.rel.dishType = "First";
            }
          });
          this.dataSource = response.dishes;
          this.price = response.price;
         }, (error) => {
          this.state = error.message;
          this.resetImage();
         },
         () => {

         });
    };
  }

}
