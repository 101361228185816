<mat-card id="component">
    <div *ngIf="mode == 'loading'">
        <div class="loadingDiv">
            <h3>{{this.state}}</h3>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
    </div>
    <div id="menu" *ngIf="mode == 'none'">
        <h2 class="title-container">Menú del día</h2>
        <h3 class="text-container">Carga un nuevo menú para el día de hoy</h3>
        <button mat-raised-button color="accent" class="sendButton" (click)="changeMode('foto')">Foto</button>
        <button mat-raised-button color="warn" class="sendButton" (click)="changeMode('manual')">Manual</button>
    </div>
    <div *ngIf="mode == 'foto'" style="height: 100%;">
        <mat-card id="pickture">
            <input #imageInput style="height: 100%;" type="file" accept="image/*" capture="user" (change)="onFileChange($event)">
            <img [src]="imageUrl" [ngStyle]="{'height': !showCancel ? '0%' : '100%'}" id="image-preview">
        </mat-card>
        <button mat-raised-button *ngIf="showValidate" color="accent" class="sendButton" (click)="scanMenu()" [disabled]="!showCancel">Validar</button>
        <button mat-raised-button *ngIf="showCancel" color="warn" class="sendButton" (click)="resetImage()">Cancelar</button>
        <div *ngIf="isLoading" class="loadingDiv">
            <h3>{{this.state}}</h3>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        </div>
        <div *ngIf="mode == 'manual'" style="height: 100%;">
            <div style="overflow: scroll; height: 80%">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 demo-table">
                    <ng-container matColumnDef="displayName">
                        <th mat-header-cell *matHeaderCellDef>Descripción</th>
                        <td mat-cell *matCellDef="let element" (click)="editDish(element)">{{element.rel.displayName}}</td>
                    </ng-container>
        
                    <ng-container matColumnDef="alergenos">
                        <th mat-header-cell *matHeaderCellDef>Alergenos</th>
                        <td mat-cell *matCellDef="let element" (click)="editDish(element)">
                            <img src="assets/Celery.png" class="allergen-icon" 
                            *ngIf="element.allergen != null && element.allergen.celery" alt="Celery"/>
                            <img src="assets/Crustaceans.png" class="allergen-icon" 
                            *ngIf="element.allergen != null && element.allergen.crustacean" alt="Crustaceans"/>
                            <img src="assets/Eggs.png" class="allergen-icon" 
                            *ngIf="element.allergen != null && element.allergen.egg" alt="Eggs"/>
                            <img src="assets/Fish.png" class="allergen-icon" 
                            *ngIf="element.allergen != null && element.allergen.fish" alt="Fish"/>
                            <img src="assets/Gluten.png" class="allergen-icon" 
                            *ngIf="element.allergen != null && element.allergen.cereal" alt="Gluten"/>
                            <img src="assets/Lupin.png" class="allergen-icon" 
                            *ngIf="element.allergen != null && element.allergen.lupin" alt="Lupin"/>
                            <img src="assets/Milk.png" class="allergen-icon" 
                            *ngIf="element.allergen != null && element.allergen.milk" alt="Milk"/>
                            <img src="assets/Mollusc.png" class="allergen-icon" 
                            *ngIf="element.allergen != null && element.allergen.mollusc" alt="Mollusc"/>
                            <img src="assets/Mustard.png" class="allergen-icon" 
                            *ngIf="element.allergen != null && element.allergen.mustard" alt="Mustard"/>
                            <img src="assets/Nut.png" class="allergen-icon" 
                            *ngIf="element.allergen != null && element.allergen.nut" alt="Nut"/>
                            <img src="assets/Peanut.png" class="allergen-icon" 
                            *ngIf="element.allergen != null && element.allergen.peanut" alt="Peanut"/>
                            <img src="assets/Sesame.png" class="allergen-icon" 
                            *ngIf="element.allergen != null && element.allergen.sesameSeed" alt="Sesame"/>
                            <img src="assets/Soy.png" class="allergen-icon" 
                            *ngIf="element.allergen != null && element.allergen.soybean" alt="Soy"/>
                            <img src="assets/Sulphurdioxide.png" class="allergen-icon" 
                            *ngIf="element.allergen != null && element.allergen.sulphurDioxide" alt="Sulphurdioxide"/>
                        </td>
                    </ng-container>
        
                    <ng-container matColumnDef="tipo">
                        <th mat-header-cell *matHeaderCellDef>Tipo</th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-fab class="first" *ngIf="element.rel.dishType == 'First'"
                                (click)="element.rel.dishType = 'Second'" aria-label="Primer Plato">
                                <h3 class="text-icon">1</h3>
                            </button>
                            <button mat-fab class="second" *ngIf="element.rel.dishType == 'Second'"
                                (click)="element.rel.dishType = 'Dessert'" aria-label="Segundo Plato">
                                <h3 class="text-icon">2</h3>
                            </button>
                            <button mat-fab class="dessert" *ngIf="element.rel.dishType == 'Dessert'"
                                (click)="element.rel.dishType = 'First'" aria-label="Postre">
                                <mat-icon class="dessert-icon">local_cafe</mat-icon>
                            </button>
                        </td>
                    </ng-container>
        
        
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <div style="height: 20%; display: flex">
                <diV style="width: 70%">
                    <button mat-raised-button color="primary" class="sendButton2" (click)="addDish()">Añadir Plato</button>
                    <button mat-raised-button color="accent" class="sendButton2" (click)="persistMenu()" [disabled]="disabledMenu">Enviar Menú</button>
                </diV>
                <div style="width: 30%">
                    <mat-form-field id="price">
                        <mat-label>Precio</mat-label>
                        <input matInput type="number" [(ngModel)]="price" />
                    </mat-form-field>
                </div>
            </div>
        </div>
        
        </mat-card>