import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';  
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';
import { MainPageComponent } from './main-page/main-page.component';
import { ScanerComponent } from './main-page/scaner/scaner.component';
import {MatListModule} from '@angular/material/list';
import { ConsignmentComponent } from './main-page/consignment/consignment.component';
import { DishDayComponent } from './main-page/dish-day/dish-day.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatInputModule} from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { RestaurantSelectorComponent } from './main-page/restaurant-selector/restaurant-selector.component';
import {MatGridListModule} from '@angular/material/grid-list';
import { SettingsComponent } from './main-page/settings/settings.component';
import { CheckDetailComponent } from './main-page/consignment/check-detail/check-detail.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTableModule} from '@angular/material/table';
import { DishDetailComponent } from './main-page/dish-day/dish-detail/dish-detail.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MapComponent } from './main-page/map/map.component';
import { EstablishmentDetailComponent } from './main-page/map/establishment-detail/establishment-detail.component';
import {MatSliderModule} from '@angular/material/slider';
import { HashLocationStrategy } from '@angular/common';
import {MatCheckboxModule} from '@angular/material/checkbox';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainPageComponent,
    ScanerComponent,
    ConsignmentComponent,
    DishDayComponent,
    RestaurantSelectorComponent,
    SettingsComponent,
    CheckDetailComponent,
    DishDetailComponent,
    MapComponent,
    EstablishmentDetailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    MatCardModule,
    HttpClientModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatExpansionModule,
    MatListModule,
    MatProgressBarModule,
    MatInputModule,
    FormsModule,
    MatGridListModule,
    MatDialogModule,
    MatTableModule,
    MatSnackBarModule,
    MatSliderModule,
    MatCheckboxModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { 
  title = 'WebAppOCR';
 }
