<div id="dialog-content">
    <div class="o-box--l">
        <div class="o-content--m">
            <h2 class="title--main">{{ restaurant.nombre | uppercase }}</h2>
            <div *ngIf="restaurant.telephone !== '0'" class="align-right">
                <mat-icon class="b-icon">phone</mat-icon>
                {{ restaurant.telephone }}
            </div>
        </div>
        <div *ngIf="isLoading">
            <div class="loadingDiv o-content--l o-content--no-top">
                <h3>Cargando...</h3>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
        </div>
        <div *ngIf="!isError && (firstCourseList || secondCourseList || dessertList)" class="o-box b-modal-info-section">
            <div class="b-header b-header__menu">
                <h3 class="b-title title-menu">Menú</h3>
                <div class="b-title">{{ menu.price }} €</div>
            </div>
            <div class="b-table-vertical">
                <div class="b-title">Primer plato</div>
                <!-- <div> -->
                    <ul class="o-box--m">
                        <li *ngFor="let dish of firstCourseList">
                            {{dish.rel.displayName | titlecase }} 
                            <div *ngIf="dish.allergen">
                                <img src="assets/Celery.png" class="allergen-icon" 
                                *ngIf="dish.allergen.celery" alt="Celery"/>
                                <img src="assets/Crustaceans.png" class="allergen-icon" 
                                *ngIf="dish.allergen.crustacean" alt="Crustaceans"/>
                                <img src="assets/Eggs.png" class="allergen-icon" 
                                *ngIf="dish.allergen.egg" alt="Eggs"/>
                                <img src="assets/Fish.png" class="allergen-icon" 
                                *ngIf="dish.allergen.fish" alt="Fish"/>
                                <img src="assets/Gluten.png" class="allergen-icon" 
                                *ngIf="dish.allergen.cereal" alt="Gluten"/>
                                <img src="assets/Lupin.png" class="allergen-icon" 
                                *ngIf="dish.allergen.lupin" alt="Lupin"/>
                                <img src="assets/Milk.png" class="allergen-icon" 
                                *ngIf="dish.allergen.milk" alt="Milk"/>
                                <img src="assets/Mollusc.png" class="allergen-icon" 
                                *ngIf="dish.allergen.mollusc" alt="Mollusc"/>
                                <img src="assets/Mustard.png" class="allergen-icon" 
                                *ngIf="dish.allergen.mustard" alt="Mustard"/>
                                <img src="assets/Nut.png" class="allergen-icon" 
                                *ngIf="dish.allergen.nut" alt="Nut"/>
                                <img src="assets/Peanut.png" class="allergen-icon" 
                                *ngIf="dish.allergen.peanut" alt="Peanut"/>
                                <img src="assets/Sesame.png" class="allergen-icon" 
                                *ngIf="dish.allergen.sesameSeed" alt="Sesame"/>
                                <img src="assets/Soy.png" class="allergen-icon" 
                                *ngIf="dish.allergen.soybean" alt="Soy"/>
                                <img src="assets/Sulphurdioxide.png" class="allergen-icon" 
                                *ngIf="dish.allergen.sulphurDioxide" alt="Sulphurdioxide"/>
                            </div>
                        </li>
                    </ul>
                <!-- </div> -->
                <div class="b-title">Segundo plato</div>
                <!-- <div> -->
                    <ul class="o-box--m">
                        <li *ngFor="let dish of secondCourseList">
                            {{dish.rel.displayName | titlecase }}
                            <div *ngIf="dish.allergen">
                                <img src="assets/Celery.png" class="allergen-icon" 
                                *ngIf="dish.allergen.celery" alt="Celery"/>
                                <img src="assets/Crustaceans.png" class="allergen-icon" 
                                *ngIf="dish.allergen.crustacean" alt="Crustaceans"/>
                                <img src="assets/Eggs.png" class="allergen-icon" 
                                *ngIf="dish.allergen.egg" alt="Eggs"/>
                                <img src="assets/Fish.png" class="allergen-icon" 
                                *ngIf="dish.allergen.fish" alt="Fish"/>
                                <img src="assets/Gluten.png" class="allergen-icon" 
                                *ngIf="dish.allergen.cereal" alt="Gluten"/>
                                <img src="assets/Lupin.png" class="allergen-icon" 
                                *ngIf="dish.allergen.lupin" alt="Lupin"/>
                                <img src="assets/Milk.png" class="allergen-icon" 
                                *ngIf="dish.allergen.milk" alt="Milk"/>
                                <img src="assets/Mollusc.png" class="allergen-icon" 
                                *ngIf="dish.allergen.mollusc" alt="Mollusc"/>
                                <img src="assets/Mustard.png" class="allergen-icon" 
                                *ngIf="dish.allergen.mustard" alt="Mustard"/>
                                <img src="assets/Nut.png" class="allergen-icon" 
                                *ngIf="dish.allergen.nut" alt="Nut"/>
                                <img src="assets/Peanut.png" class="allergen-icon" 
                                *ngIf="dish.allergen.peanut" alt="Peanut"/>
                                <img src="assets/Sesame.png" class="allergen-icon" 
                                *ngIf="dish.allergen.sesameSeed" alt="Sesame"/>
                                <img src="assets/Soy.png" class="allergen-icon" 
                                *ngIf="dish.allergen.soybean" alt="Soy"/>
                                <img src="assets/Sulphurdioxide.png" class="allergen-icon" 
                                *ngIf="dish.allergen.sulphurDioxide" alt="Sulphurdioxide"/>
                            </div>
                        </li>
                    </ul>
                <!-- </div> -->
                <div class="b-title">Postre</div>
                <!-- <div> -->
                    <ul class="o-box--m">
                        <li *ngFor="let dish of dessertList">
                            {{dish.rel.displayName | titlecase }}
                            <div *ngIf="dish.allergen">
                                <img src="assets/Celery.png" class="allergen-icon" 
                                *ngIf="dish.allergen.celery" alt="Celery"/>
                                <img src="assets/Crustaceans.png" class="allergen-icon" 
                                *ngIf="dish.allergen.crustacean" alt="Crustaceans"/>
                                <img src="assets/Eggs.png" class="allergen-icon" 
                                *ngIf="dish.allergen.egg" alt="Eggs"/>
                                <img src="assets/Fish.png" class="allergen-icon" 
                                *ngIf="dish.allergen.fish" alt="Fish"/>
                                <img src="assets/Gluten.png" class="allergen-icon" 
                                *ngIf="dish.allergen.cereal" alt="Gluten"/>
                                <img src="assets/Lupin.png" class="allergen-icon" 
                                *ngIf="dish.allergen.lupin" alt="Lupin"/>
                                <img src="assets/Milk.png" class="allergen-icon" 
                                *ngIf="dish.allergen.milk" alt="Milk"/>
                                <img src="assets/Mollusc.png" class="allergen-icon" 
                                *ngIf="dish.allergen.mollusc" alt="Mollusc"/>
                                <img src="assets/Mustard.png" class="allergen-icon" 
                                *ngIf="dish.allergen.mustard" alt="Mustard"/>
                                <img src="assets/Nut.png" class="allergen-icon" 
                                *ngIf="dish.allergen.nut" alt="Nut"/>
                                <img src="assets/Peanut.png" class="allergen-icon" 
                                *ngIf="dish.allergen.peanut" alt="Peanut"/>
                                <img src="assets/Sesame.png" class="allergen-icon" 
                                *ngIf="dish.allergen.sesameSeed" alt="Sesame"/>
                                <img src="assets/Soy.png" class="allergen-icon" 
                                *ngIf="dish.allergen.soybean" alt="Soy"/>
                                <img src="assets/Sulphurdioxide.png" class="allergen-icon" 
                                *ngIf="dish.allergen.sulphurDioxide" alt="Sulphurdioxide"/>
                            </div>
                        </li>
                    </ul>
                <!-- </div> -->
            </div>
        </div>
    </div>
</div>