import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PresentedCheck } from 'src/app/data_structure/PresentedCheck';
import { RelAllergen } from 'src/app/data_structure/RelAllergen';
import { WholeDish } from 'src/app/data_structure/WholeDish';

@Component({
  selector: 'app-dish-detail',
  templateUrl: './dish-detail.component.html',
  styleUrls: ['./dish-detail.component.scss']
})
export class DishDetailComponent {

  public allergens = <RelAllergen>{};

  constructor(
    public dialogRef: MatDialogRef<DishDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WholeDish){
      if(data.allergen == null) {
        this.allergens.celery = false;
        this.allergens.cereal = false;
        this.allergens.crustacean = false;
        this.allergens.egg = false;
        this.allergens.fish = false;
        this.allergens.lupin = false;
        this.allergens.milk = false;
        this.allergens.mollusc = false;
        this.allergens.mustard = false;
        this.allergens.nut = false;
        this.allergens.peanut = false;
        this.allergens.sesameSeed = false;
        this.allergens.soybean = false;
        this.allergens.sulphurDioxide = false;
      }
      else{
        this.allergens = data.allergen;
      }
    }


    onNoClick(): void {
      if(this.allergens.celery == true || this.allergens.cereal == true || this.allergens.crustacean == true || this.allergens.egg == true
        || this.allergens.fish == true || this.allergens.lupin == true || this.allergens.milk == true || this.allergens.mollusc == true
        || this.allergens.mustard == true || this.allergens.nut == true || this.allergens.peanut == true || this.allergens.sesameSeed == true
        || this.allergens.soybean == true || this.allergens.sulphurDioxide == true){
          this.data.allergen = this.allergens;
        }
        else{
          //this.data.allergen = <RelAllergen>{};
        }
      this.dialogRef.close();
    }
  
    public unregister(wholeDish: WholeDish){
      this.dialogRef.close(wholeDish);
    }

}
