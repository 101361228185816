<mat-card id="component">
  <div>
    <h2 class="title-card">Selector de Restaurantes</h2>
  </div>
    <div *ngFor="let establishment of establishmentList">
        <button mat-raised-button (click)="changeEstablishment(establishment)" type="button" class="establishment" [ngClass]="{'selected': establishment.id == selectedRestaurant}">
            <span>{{establishment.name}} ({{establishment.id}})</span>
            <mat-icon *ngIf="establishment.hasCheckPresented" class="icon-menu" [ngStyle]="{'color':'green'}">receipt</mat-icon>
            <mat-icon *ngIf="!establishment.hasMenuToday" class="icon-menu" [ngStyle]="{'color':'red'}">ballot</mat-icon>
          </button>
        </div>
</mat-card>