<mat-card id="component">
    <div id="map" class="ol-map" (click)="onClickMap($event)"></div>
    <div class="searchArea">
        <div id="searchAreaDiv" class="divSeach">
            <mat-form-field class="field-login">
                <mat-label>Dirección</mat-label>
                <input matInput placeholder="calle costa del sol, 11, madrid" [(ngModel)]="searchArea.address">
            </mat-form-field>
            <div>
                <div class="example-label-container">
                    <label id="example-name-label" class="example-name-label">Radio {{searchArea.radius}}m</label>
                </div>
                <mat-slider min="100" max="1000" step="50" value="200">
                    <input matSliderThumb [(ngModel)]="searchArea.radius">
                </mat-slider>
            </div>
        </div>
        
        <div (click)="switchSearchDish()" class="form-button-show"  [class.show-less]="isSearchDish">
            Búsqueda por menú <mat-icon *ngIf="!isSearchDish" class="b-icon">expand_more</mat-icon><mat-icon *ngIf="isSearchDish" class="b-icon">expand_less</mat-icon>
        </div>
        <mat-checkbox *ngIf="isSearchDish" class="field-search" [(ngModel)]="searchArea.menu">Menú del día</mat-checkbox>
        <mat-form-field *ngIf="isSearchDish" class="field-search">
            <mat-label>Plato</mat-label>
            <input matInput placeholder="Patatas & Calamares" [disabled]="!searchArea.menu" [(ngModel)]="searchArea.dish">
        </mat-form-field>

        <button mat-raised-button color="accent" class="sendButton" id="logInButton" (click)="getEstablishments()" [disabled]="isLoading">
            Buscar
        </button>
        <!-- <button class="b-btn b-btn--big" id="logInButton" (click)="getEstablishments()" [disabled]="isLoading">Buscar</button> -->
    </div>


    <!-- <div class="loadingDiv">
                <h3 *ngIf="msgLogin">{{this.state}}</h3>
                <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
            </div> -->

    <!-- Pointer events polyfill for old browsers, see https://caniuse.com/#feat=pointer -->
    <!-- <script src="https://cdn.jsdelivr.net/npm/elm-pep@1.0.6/dist/elm-pep.js"></script>
    <script type="module" src="main.js"></script> -->
</mat-card>