<mat-card id="component">
    <mat-card id="pickture">
        <input #imageInput type="file" style="height: 100%;" accept="image/*" capture="user" (change)="onFileChange($event)">
        <img [src]="imageUrl" [ngStyle]="{'height': !showCancel ? '0%' : '100%'}" id="image-preview">
    </mat-card>
    <button mat-raised-button *ngIf="showValidate" color="accent" class="sendButton" (click)="scanCheck()" [disabled]="!showCancel">Validar</button>
    <button mat-raised-button *ngIf="showCancel" color="warn" class="sendButton" (click)="resetImage()">Cancelar</button>
    <button mat-raised-button *ngIf="showNewCheck" color="accent" class="sendButton" (click)="resetImage()">Nuevo Intento</button>
    <div *ngIf="isLoading" class="loadingDiv">
        <h3>{{this.state}}</h3>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div id="results" *ngIf="hasResult">
        <h3>{{this.result}} / Código: {{this.code}}</h3>
        <h3>Número de cheque: {{this.CheckNumber}}</h3>
        <h3>Año: {{this.Year}}</h3>
        <h3>Importe: {{this.Quantity}}</h3>
        <h3>Caducidad: {{this.ExpirationDate}}</h3>
    </div>

</mat-card>