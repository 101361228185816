import { Component, EventEmitter, Output } from '@angular/core';
import { RelEstablishment } from 'src/app/data_structure/RelEstablishment';
import { UserSingleton } from 'src/app/services/user-singleton';

@Component({
  selector: 'app-restaurant-selector',
  templateUrl: './restaurant-selector.component.html',
  styleUrls: ['./restaurant-selector.component.scss']
})
export class RestaurantSelectorComponent {

  public establishmentList?: RelEstablishment[];
  private userSingleton: UserSingleton;
  public selectedRestaurant = 0;

  @Output()
  establishmentChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor(private _userSingleton: UserSingleton) {
    this.userSingleton = _userSingleton;
    this.establishmentList = this.userSingleton.getUser().listEstablishment;
    this.selectedRestaurant = this.userSingleton.getEstablishment().id;
  }

  public changeEstablishment(establishment: RelEstablishment) {
    this.userSingleton.saveEstablishment(establishment);
    this.selectedRestaurant = establishment.id;
    this.establishmentChanged.emit();
  }

}
