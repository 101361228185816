import { Component } from '@angular/core';
import { UserSingleton } from 'src/app/services/user-singleton';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {

  public isChecked = false;
  private userSingleton: UserSingleton;

  constructor(_userSingleton: UserSingleton) {
    this.userSingleton = _userSingleton;
    this.isChecked = this.userSingleton.getPhotoConfig() == "true" ? true : false; 
  }

  public changeValue(){
    this.userSingleton.savePhotoConfig(this.isChecked ? "true" : "false");
    localStorage.setItem('savePhto', this.isChecked ? "true" : "false");
  }

}
