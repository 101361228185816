import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PresentedCheck } from 'src/app/data_structure/PresentedCheck';
import { OCRService } from 'src/app/services/ocr.service';
import { UserSingleton } from 'src/app/services/user-singleton';

@Component({
  selector: 'app-check-detail',
  templateUrl: './check-detail.component.html',
  styleUrls: ['./check-detail.component.scss']
})
export class CheckDetailComponent {

  public showPhoto = false;
  public formattedDate = "";

  private userSingleton: UserSingleton;
  private ocrService;

  constructor(
    public dialogRef: MatDialogRef<CheckDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PresentedCheck, private _ocrService: OCRService, _userSingleton: UserSingleton) {
      if(data.urlImg.length > 40){
        this.showPhoto = true;
      }
      this.formattedDate = data.expirationDate.toString().split('T')[0];
      this.userSingleton = _userSingleton;
      this.ocrService = _ocrService;
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public unregister(WholeCheckNumber: string){
    this.ocrService.unregisterCheck(this.userSingleton.getEstablishment().id, WholeCheckNumber).subscribe((response: boolean) =>{
      this.dialogRef.close(response);
    });
  }
  
}
