<mat-toolbar color="accent" class="toolbar">
    <h1 class="title">Up Spain</h1>
	<button mat-icon-button (click)="showMenu()"><mat-icon>menu</mat-icon></button>
</mat-toolbar>
<mat-sidenav-container class="menu" autosize *ngIf="isExpanded">
	<mat-sidenav  class="example-sidenav" mode="side" opened="true">
		<mat-nav-list id="list-menu">
            <textarea  type="text" readonly class="full-width-text" [(ngModel)]="this.textRestaurant">
			</textarea>
			<!--<mat-list-item (click)="changeCategory('scaner')">
				<span class="full-width">Escaner</span>
			</mat-list-item>
            <mat-list-item (click)="changeCategory('consignment')">
				<span class="full-width">Remesas</span>
                <mat-icon *ngIf="userSingleton.getEstablishment().hasCheckPresented" class="icon-menu" [ngStyle]="{'color':'green'}">sms</mat-icon>
			</mat-list-item>-->
            <mat-list-item (click)="changeCategory('dish')">
				<span class="full-width">Menú del día</span>
				<mat-icon *ngIf="!userSingleton.getEstablishment().hasMenuToday" class="icon-menu" [ngStyle]="{'color':'red'}">ballot</mat-icon>
			</mat-list-item>
            <mat-list-item *ngIf="moreRestaurants" (click)="changeCategory('restaurant')">
				<span class="full-width">Selector de restaurante</span>
			</mat-list-item>
			<mat-list-item (click)="changeCategory('map')">
				<span class="full-width">Mapa</span>
			</mat-list-item>
			<!--<mat-list-item (click)="changeCategory('settings')">
				<span class="full-width">Ajustes</span>
			</mat-list-item>-->

		</mat-nav-list>
		<mat-list-item (click)="logOff()">
			<span class="full-width">Desconectarse</span>
			<mat-icon class="icon-menu" [ngStyle]="{'color':'black'}">power_settings_new</mat-icon>
		</mat-list-item>
	</mat-sidenav>
</mat-sidenav-container>

<div class="content" [ngClass]="{'content-menu-open': isExpanded}" (click)="closeMenu()">
    <app-scaner *ngIf="current == 'scaner'"></app-scaner>
    <app-consignment *ngIf="current == 'consignment'"></app-consignment>
    <app-dish-day *ngIf="current == 'dish'"></app-dish-day>
    <app-restaurant-selector (establishmentChanged)="establishmentChanged($event)" *ngIf="current == 'restaurant'"></app-restaurant-selector>
	<app-map *ngIf="current == 'map'"></app-map>
	<app-settings *ngIf="current == 'settings'"></app-settings>
</div>



