import { Component, OnInit  } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OCRCheck } from 'src/app/data_structure/OCRCheck';
import { PartialShipment } from 'src/app/data_structure/PartialShipment';
import { PresentedCheck } from 'src/app/data_structure/PresentedCheck';
import { OCRService } from 'src/app/services/ocr.service';
import { UserSingleton } from 'src/app/services/user-singleton';
import { CheckDetailComponent } from './check-detail/check-detail.component';

@Component({
  selector: 'app-consignment',
  templateUrl: './consignment.component.html',
  styleUrls: ['./consignment.component.scss']
})
export class ConsignmentComponent implements OnInit {

  private userSingleton: UserSingleton;
  private ocrService;
  public presentedChecks?: PresentedCheck[];
  public isLoading = true;
  public textLoading = true;
  public state = "";
  public showPhoto = "";
  public total = 0;

  constructor(private _ocrService: OCRService, _userSingleton: UserSingleton, public dialog: MatDialog, public snackBar: MatSnackBar) {
    this.ocrService = _ocrService;
    this.userSingleton = _userSingleton;
    
  }

  ngOnInit(){
    this.showPhoto = localStorage.getItem('savePhto')!;
    this.loadChecks();
  }

  private loadChecks(){
    this.state = "Cargando";
    this.total = 0;
    console.log(this.showPhoto);
    this.ocrService.getChecks(this.userSingleton.getEstablishment().id, this.showPhoto)
    .subscribe((response: PresentedCheck[]) => {
      console.log(response);
      this.presentedChecks = response;
      this.isLoading = false;
      if(response === null){
        this.state = "No tienes cheques pendientes en este establecimiento";
      }
      else{
        this.textLoading = false;
      }
      response.forEach(element => {
        this.total += element.amount;
        element.urlImg = 'data:image/jpg;base64,'+ element.scannedImg;
      });
    },
    (error) =>{
      this.isLoading = false;
      this.state = error.message;
    })
  }

  public sendConsignment() {
    this.isLoading = true;
    this.textLoading = true;
    let body = <PartialShipment>{}
    body.vat = "Example vat";
    body.totalAmount = this.total;
    body.affiliatedId = this.userSingleton.getEstablishment().id;
    this.ocrService.sendConsignment(body).subscribe((response: boolean) => {
      this.isLoading = false;
      this.textLoading = false;
      if(response){
        this.snackBar.open("Remesa Enviada", "Cerrar", {
          duration: 6000,
        });
      }
      else{
        this.snackBar.open("Hubo un problema al enviar la remesa", "Cerrar", {
          duration: 6000,
        });
      }
    }), (error: any) => {
      this.isLoading = false;
      this.snackBar.open(error.message, "Cerrar", {
        duration: 10000,
      })
    };
  }

  public detailCheck(check: PresentedCheck){
    const dialogRef = this.dialog.open(CheckDetailComponent, {
      width: '100%',
      height: '65%',
      maxWidth: '97%',
      position: {left: '2.5%', right: '2.5%'},
      data: check
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.loadChecks();
      }
    });
  }

}
