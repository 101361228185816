import { EstablishmentLocation } from './../data_structure/EstablishmentLocation';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { OCRResponse } from '../data_structure/OCRResponse';
import { OCRCheck } from '../data_structure/OCRCheck';
import { ResultLogin } from '../data_structure/ResultLogin';
import { PresentedCheck } from '../data_structure/PresentedCheck';
import { WholeDish } from '../data_structure/WholeDish';
import { DataNewDish } from '../data_structure/DataNewDish';
import { DataNewMenu } from '../data_structure/DataNewMenu';
import { WholeMenu } from '../data_structure/WholeMenu';
import { environment } from 'src/environments/environment';
import { PartialShipment } from '../data_structure/PartialShipment';
import { ResponseEstablishmentLocation } from '../data_structure/ResponseEstablishmentLocation';

@Injectable({
  providedIn: 'root'
})
export class OCRService {
  constructor(private http: HttpClient) { }
  
  //urlBase = 'http://10.100.100.97:8085/Api/OCR/PostCheck';
  //urlBase = 'http://192.168.1.86:8085/Api/OCR/PostCheck';
  urlBase = environment.backURL;
  
  postMenu(base64?: string, establishment?: number) {
    return this.http.post<WholeMenu>(this.urlBase + "Establishment/AnalizeMenu", JSON.stringify(base64), { 
      headers: new HttpHeaders({"Content-Type": "application/json", "establishment": establishment!.toString()
    })
  });
  }

  sendConsignment(body?: PartialShipment) {
    return this.http.post<boolean>(this.urlBase + "Establishment/SendConsignment", JSON.stringify(body), { 
      headers: new HttpHeaders({"Content-Type": "application/json"
    })
  });
  }

  getMenu(establishment?: number) {
    return this.http.get<WholeMenu>(this.urlBase + "Guest/GetMenu", { 
      headers: new HttpHeaders({"Content-Type": "application/json", "establishment": establishment!.toString()
    })
  });
  }

  getMap(street?: string, radius?: number, withMenu?: boolean, dish?: string) {
    if(dish == undefined)
      dish = "";
    if(withMenu == undefined)
      withMenu = false;

    return this.http.get<ResponseEstablishmentLocation>(this.urlBase + "Guest/GetMap", { 
      headers: new HttpHeaders({"Content-Type": "application/json", "street": street!, "radius": radius!.toString(),
      "dish": dish, "withMenu": withMenu.toString()
    })
  });
  }


  persistMenu(data?: DataNewMenu, establishment?: number) {
    return this.http.post<boolean>(this.urlBase + "Establishment/PersistMenu", JSON.stringify(data), { 
      headers: new HttpHeaders({"Content-Type": "application/json", "establishment": establishment!.toString()
    })
  });
  }

  postCheck(base64?: string, establishment?: number) {
    return this.http.post<OCRCheck>(this.urlBase + "Establishment/PostCheck", JSON.stringify(base64), { 
      headers: new HttpHeaders({"Content-Type": "application/json", "establishment": establishment!.toString()
    })
  });
  }

  unregisterCheck(establishment: number, wholeCheckNum: string) {
    return this.http.put<boolean>(this.urlBase + "Establishment/UnregisterCheck", '', { 
      headers: new HttpHeaders({"establishment": establishment.toString(), "Content-Type": "application/json",
      "wholeCheckNum": wholeCheckNum
    })
  });
  }

  getChecks(establishment: number, withImg: string) {
    return this.http.get<PresentedCheck[]>(this.urlBase + "Establishment/GetChecksEstablishment", { 
      headers: new HttpHeaders({"establishment": establishment.toString(), "Content-Type": "application/json",
      "withImg": withImg
    })
  });
  }

  logIn(user: string, password: string) {
    return this.http.get<ResultLogin>(this.urlBase + "Login/LoginWebApp", { 
      headers: new HttpHeaders({"user": user, "password": password, "Content-Type": "application/json"
    })
  });
  }
}

