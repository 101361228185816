import { Component } from '@angular/core';
import { OCRService } from '../services/ocr.service';
import { OCRResponse } from '../data_structure/OCRResponse';
import { ResultLogin } from '../data_structure/ResultLogin';
import { Router } from '@angular/router';
import { UserSingleton } from '../services/user-singleton';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [OCRService]
})
export class LoginComponent {

  public isLoading = false;
  public msgLogin = false;
  public disableLogin = true;
  public username = "";
  public password = "";
  public state = "";

  private ocrService;
  private userSingleton;

  constructor(private _ocrService: OCRService, private router: Router, private _userSingleton: UserSingleton,
    private jwtHelper : JwtHelperService) {
    this.ocrService = _ocrService;
    this.userSingleton = _userSingleton;
  }

  public checkLogin() {
    if(this.username != "" && this.password != "") {
      this.disableLogin = false;
    }
    else{
      this.disableLogin = true;
    }
  }

  isUserAuthenticated() {
    const token = localStorage.getItem("jwt");
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      return true;
    }
    else {
      return false;
    }
  }

  public logInGuest(){
    this.router.navigate(['map']);
  }

  public logIn() {
    this.state = "Cargando";
    this.isLoading = true;
    this.msgLogin = true;
    this.ocrService.logIn(this.username, this.password).subscribe((response: ResultLogin) => {
      this.isLoading = false;
      localStorage.setItem("jwt", response.token);
      if(!response.result){
        this.state = "Login Incorrecto";
        this.msgLogin = true;
      }
      else{
        this.msgLogin = false;
        this.state = "";
      }

      if(this.userSingleton.getUser() == null){
        this.userSingleton.savePhotoConfig("true");
      }
      this.userSingleton.saveUser(response);
      this.userSingleton.saveEstablishment(response.listEstablishment[0]);
      this.router.navigate(['main-page']);
     },
     (error) => {
      this.isLoading = false;
      this.state = error.message;
    }
    );
  }

}
